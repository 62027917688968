<template>
  <div>
    <v-divider></v-divider>
    <v-row>
      <v-col>
        <associatedOption
          :fieldAttributes="{ name: 'Retry',options: fieldAttributes.retry}"
          type="select"
          :fieldAttrInput="{}"
          :value="retry"
          @input="updateRetry(...arguments)"
        ></associatedOption>
      </v-col>
      <v-col>
        <associatedOption
          :fieldAttributes="{ name: 'End',options: fieldAttributes.end}"
          type="select"
          :fieldAttrInput="{}"
          :value="end"
          @input="updateEnd(...arguments)"
        ></associatedOption>
      </v-col>
      <HelpButton id="onError_block" class="mt-3 ml-n3"></HelpButton>
    </v-row>
  </div>
</template>
<script>
  import associatedOption from "@/commonComponents/associatedOption.vue";
  import HelpButton from "@/components/basic/HelpButton.vue";
  export default {
    components: {HelpButton, associatedOption },
    props: ["field", "fieldAttributes","value"],
    computed: {
      localValue() {
        let localValue = "";
        if (typeof this.value != "undefined") {
          localValue = this.value;
        }
        return localValue;
      },
      retry: {
        get() {
          console.log("retry computed called", this.localValue);
          let evalArray = this.localValue.split(";");
          return parseInt(evalArray[1]);
        }
      },
      end: {
        get() {
          let evalArray = this.localValue.split(";");
          return evalArray[2];
        }
      }
    },
    methods: {
      updateRetry(newVal) {
        let evalString = "retry;" + newVal + ";" + this.end;
        this.$emit("input", evalString);
      },
      updateEnd(newVal) {
        let evalString = "retry;" + this.retry + ";" + newVal;
        this.$emit("input", evalString);
      }
    }
  };
</script>